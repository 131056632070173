import React from 'react'
import { graphql } from 'gatsby'

import { useTranslation } from 'react-i18next'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from 'Common/Hero'
import BlogPostCarouselSection from 'Section/BlogPostCarousel'
import Features from 'Section/Features'
import TillComparison from 'Section/TillComparison'
import Contact from 'Section/Contact'
import FeaturedCaseStudy from 'Section/FeaturedCaseStudy'
import GoCashless from 'Section/GoCashless'
import WhitepaperDownload from 'Common/WhitepaperDownload'
import Video from 'Section/Video'
import getTranslation from '../../src/utility/language-picker'
import config from '../../../config'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description {
        value
        _key
      }
      keywords
    }

    home: sanityHomePage(_id: { regex: "/(drafts.|)homePage/" }) {
      title {
        value
        _key
      }
      subtitle {
        value
        _key
      }
      minutesSaved
      features {
        title {
          value
          _key
        }
        _rawDescription
        features {
          title {
            value
            _key
          }
          _rawDescription
          smallTitle
          image {
            asset {
              mimeType
              url
              _id
            }
          }
        }
      }
      tillComparison {
        title {
          value
          _key
        }
        _rawDescription
      }
      goCashless {
        title {
          value
          _key
        }
        _rawDescription
      }
      featuredCaseStudy {
        _rawDescription
        caseStudy {
          title {
            value
            _key
          }
          _rawExcerpt(resolveReferences: { maxDepth: 10 })
          image {
            asset {
              url
              _id
            }
          }
        }
      }
      whitepaperSection {
        title {
          value
          _key
        }
        _rawDescription
        buttonText {
          value
          _key
        }
      }
      contactSection {
        title {
          value
          _key
        }
        _rawDescription
        email {
          value
          _key
        }
        phone {
          value
          _key
        }
        address {
          value
          _key
        }
        ctaLabel {
          value
          _key
        }
        calendlyUrl
        salesDeck {
          asset {
            url
          }
        }
      }
    }

    email: sanityWhitepaperEmail(
      _id: { regex: "/(drafts.|)whitepaperEmail/" }
    ) {
      subject
      emailBody
      title
      attachment {
        asset {
          url
        }
      }
    }

    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { isFeatured: { eq: true } }
    ) {
      edges {
        node {
          id
          publishedAt
          isFeatured
          mainImage {
            asset {
              url
              _id
            }
          }
          category {
            title
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const IndexPage = (props) => {
  const { data, errors } = props
  const { t } = useTranslation()
  const siteLanguage = config.site.language || 'en'

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = getTranslation((data || {}).site)
  const home = (data || {}).home
  // const email = (data || {}).email

  // const postNodes = (data || {}).posts
  //   ? mapEdgesToNodes(data.posts).filter(filterOutDocsWithoutSlugs)
  //   : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  if (!home) {
    throw new Error(
      'Missing "Home content". Open the studio at http://localhost:3333 and add some content to "Pages/Home" and restart the development server.'
    )
  }

  const {
    mobileHero,
    minutesSaved,
    featuredCaseStudy,
    video
  } = home

  const contactSection = getTranslation(home.contactSection)
  const whitepaperSection = getTranslation(home.whitepaperSection)
  const goCashless = getTranslation(home.goCashless)
  const tillComparison = getTranslation(home.tillComparison)
  const features = getTranslation(home.features)
  features.features.forEach((_, i) => {
    features.features[i] = getTranslation(features.features[i])
  })

  const languagePicker = (languages) => {
    return languages.filter((language) => language._key === siteLanguage)[0].value
  }
  const title = languagePicker(home.title)
  const subtitle = languagePicker(home.subtitle)

  return (
    <Layout hideBrandingBg location={props.location}>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      {home && (
        <Hero
          title={title}
          mobileHero={mobileHero}
          subtitle={subtitle}
          minutesSaved={minutesSaved}
        />
      )}
      <Video
        title={t('home.video.title')}
        description={t('index.video.description')}
        additionalParams={{
          autoplay: 1,
          loop: 1,
          muted: 1,
        }}
        video={{
          videoId: '997664795',
          videoType: 'vimeo'
        }}
      />
      {features && (
        <Features
          title={features.title}
          description={features._rawDescription}
          features={features.features}
        />
      )}
      {goCashless && (
        <GoCashless
          title={goCashless.title}
          description={goCashless._rawDescription}
        />
      )}
      {tillComparison && (
        <TillComparison
          title={tillComparison.title}
          description={tillComparison._rawDescription}
        />
      )}
      {/* {featuredCaseStudy && <FeaturedCaseStudy {...featuredCaseStudy} />} */}
      {/* {postNodes.length > 0 && (
        <BlogPostCarouselSection
          postNodes={postNodes}
          browseMoreHref="/news/"
          title="Latest news"
        />
      )} */}
      {/* <WhitepaperDownload email={email} {...whitepaperSection} /> */}
      {contactSection && <Contact {...contactSection} />}
    </Layout>
  )
}

export default IndexPage
